<template>
  <div>
    <div v-for="item in items" :key="item.id">
      <AuctionCard
        v-if="item.type == 'auction'"
        :item="item"
        @click.native="goToDetail(item.type, item.id)"
      />
      <LuckyDrawCard
        v-else-if="item.type == 'luckydraw'"
        :item="item"
        @click.native="goToDetail(item.type, item.id)"
      />
    </div>
  </div>
</template>

<script>
import AuctionCard from "@/components/card/auction.vue";
import LuckyDrawCard from "@/components/card/luckydraw.vue";

export default {
  name: "List",

  components: {
    AuctionCard,
    LuckyDrawCard,
  },

  props: ["items"],

  methods: {
    goToDetail(type, id) {
      this.$router.push({
        name: type == "auction" ? "AuctionDetail" : "LuckyDrawDetail",
        params: { id: id },
      });
    },
  },
};
</script>
