<template>
  <div>
    <div :class="{ 'd-flex': enable_luckydraw }">
      <FilterAction
        @change="fetch"
        v-model="filterBy"
        v-if="enable_luckydraw"
        :defaultSelected="filterBy"
      />
      <Sorting @change="fetch" v-model="orderBy" :defaultSelected="orderBy" />
    </div>
    <List :items="items" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";
import FilterAction from "@/views/MyList/Filter.vue";
import Sorting from "@/views/MyList/Sorting.vue";
import List from "@/views/MyList/List.vue";

export default {
  name: "MyList",

  components: {
    FilterAction,
    Sorting,
    List,
  },

  data() {
    return {
      enable_luckydraw: +process.env.VUE_APP_ENABLE_LUCKYDRAW === 1,
    };
  },

  apollo: {
    $subscribe: {
      eventAdded: {
        query: gql`
          subscription {
            eventAdded {
              lot_id
              user_id
              event
              points
              event_id
              product_name
            }
          }
        `,
        result({ data }) {
          const { lot_id, user_id, event, points } = data.eventAdded;
          const itemIndex = this.items.findIndex((i) => i.id === lot_id);

          if (itemIndex <= -1) {
            return;
          }

          switch (event) {
            case "success_bid":
              this.items[itemIndex].bid_points = points;
              break;

            case "new_luckydraw_user":
              this.items[itemIndex].luckydraw_user_count += 1;
              break;

            default:
              break;
          }
        },
      },
    },
  },

  computed: {
    ...mapGetters({
      items: `favorite/list`,
      params: `favorite/params`,
    }),

    filterBy: {
      get() {
        return this.params.type;
      },
      set(value) {
        this.setFilterType(value);
      },
    },

    orderBy: {
      get() {
        return this.params.order_by;
      },
      set(value) {
        this.setOrderBy(value);
      },
    },
  },

  methods: {
    ...mapActions({
      fetch: `favorite/fetch`,
      setOrderBy: `favorite/setOrderBy`,
      setFilterType: `favorite/setFilterType`,
    }),
  },
};
</script>

<style></style>
