<template>
  <div class="sheet modal-page" :class="{ active: ready }">
    <div class="sheet-content">
      <p class="title">
        เว็บไซต์นี้ใช้คุ้กกี้ในการเก็บข้อมูล เพื่อให้ท่านได้<br />ประโยชน์จาการใช้งานอย่างดีที่สุด
      </p>
      <div class="row row-action">
        <div class="col-6 px-2">
          <button
            type="button"
            @click="$emit('accept')"
            class="btn btn-primary btn-lg btn-block"
          >
            ยอมรับ
          </button>
        </div>
        <div class="col-6 px-2">
          <button
            type="button"
            @click="$emit('read')"
            class="btn btn-default btn-lg btn-block"
          >
            อ่านเพิ่ม
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.ready = true;
    }, 450);
  },
};
</script>

<style lang="scss" scoped>
.sheet-content {
  width: 100%;
  bottom: 0px;
  height: 180px;
  padding: 15px;
  position: absolute;
  border-radius: 1px;
  background-color: #1d4d80;

  .title {
    font-weight: 400;
    color: #ffffff;
    line-height: 1.25;
  }

  .row-action {
    width: 100%;
    bottom: 36px;
    padding: 0px 12px;
    position: absolute;

    .btn-default {
      color: #ffffff;
      border-color: #ffffff;
    }
  }
}
</style>
