<template>
  <div class="wrapper">
    <StatusBar :item="item" />
    <Navbar :counter="item.no_visit" />
    <Header :item="item" />
    <Swiper :images="images" />
    <Form
      ref="form"
      :item="item"
      @success="onDrawSuccess"
      @error="onDrawError"
    />
    <DateRange :item="item" />
    <History :histories="item.luckydraw_history" :user="userInfo.user_id" />
    <Info :item="item" />
    <transition
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <AlertSheet v-if="showAlertSheet" @submit="showAlertSheet = false">
        <div class="text-center">
          <p class="h2 my-4">
            {{ drawErrorMessage }}
          </p>
        </div>
      </AlertSheet>
    </transition>
  </div>
</template>

<script>
import gql from "graphql-tag";

import { getLotById } from "@/api/http";
import { mapActions, mapGetters } from "vuex";
import StatusBar from "@/views/Detail/luckydraw/StatusBar.vue";
import Header from "@/views/Detail/Header.vue";
import Swiper from "@/views/Detail/Swiper.vue";
import DateRange from "@/views/Detail/DateRange.vue";
import Form from "@/views/Detail/luckydraw/Form.vue";
import History from "@/views/Detail/luckydraw/History.vue";
import Info from "@/views/Detail/Info.vue";
import EndedSheet from "@/views/Detail/luckydraw/EndedSheet.vue";
import AlertSheet from "@/components/sheet/AlertSheet.vue";
import PopupOverlay from "@/components/shared/Popup.vue";

export default {
  name: "LuckyDrawDetail",

  components: {
    StatusBar,
    Header,
    Swiper,
    DateRange,
    Form,
    History,
    Info,
    AlertSheet,
    EndedSheet,
  },

  apollo: {
    $subscribe: {
      eventAdded: {
        query: gql`
          subscription {
            eventAdded {
              lot_id
              user_id
              event
              points
              event_id
              product_name
            }
          }
        `,
        result({ data }) {
          const { event, lot_id } = data.eventAdded;

          if (this.item.id === lot_id && event === "lot_aborted") {
            this.item.status = "aborted";
            this.$refs.form.canDraw = false;
            this.$refs.form.initUpDownButtonState();
            return;
          }

          if (this.item.id === lot_id) {
            this.fetchLot();
          }
        },
      },
    },
  },

  data() {
    return {
      item: {},
      drawErrorMessage: "",
      showAlertSheet: false,
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),

    images() {
      const { image1, image2, image3, image4, image5 } = this.item;
      return [image1, image2, image3, image4, image5].filter((i) => i != null);
    },
  },

  async created() {
    await this.fetchUserInfo();
    await this.fetchLot();
  },

  methods: {
    ...mapActions(["fetchUserInfo"]),

    async fetchLot() {
      try {
        const id = this.$route.params.id;
        const response = await getLotById(id);
        this.item = Object.assign({}, { id: id }, response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async onDrawSuccess() {
      this.fetchLot();
      this.$modal.show(
        PopupOverlay,
        { message: `คำสั่งของคุณกำลังถูกดำเนินการ` },
        {
          width: "80%",
          height: "auto",
          classes: "result-message",
        }
      );

      setTimeout(this.$modal.hideAll, 3000);
    },

    async onDrawError(message) {
      this.drawErrorMessage = message;
      this.showAlertSheet = true;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 55px;
  background-color: #f9fbfc;
}

.animated {
  animation-duration: 0.45s;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
