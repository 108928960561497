<template>
  <div class="card mb-3 mx-2">
    <div class="row no-gutters">
      <div class="image">
        <img
          class="card-img align-self-center mx-auto"
          :alt="item.name"
          :src="item.image"
          v-holder="{ img: '125x236', bg: 'dcdcdc' }"
        />
      </div>
      <div class="body">
        <div class="card-body">
          <h5 class="card-title">{{ item.name }}</h5>
          <div class="card-text">
            <div class="d-flex justify-content-start text-muted">
              <span>เริ่ม</span>
              <span>{{ item.start_date | date_med }}</span>
            </div>
            <div class="d-flex justify-content-start text-muted">
              <span>สิ้นสุด</span>
              <span>{{ item.end_date | date_med }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-footer text-white"
      :class="{ 'bg-primary': item.is_winner }"
    >
      <div class="d-flex justify-content-between">
        <span class="align-self-center">
          <AuctionIcon v-if="item.type == 'auction'" class="mx-2" />
          <LuckyDrawIcon v-else-if="item.type == 'luckydraw'" class="mx-2" />
          {{ statusText }}
        </span>
        <p class="btn btn-link text-white mb-0">
          <span>ดูรายละเอียด</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getHistoryStatusText as getStatusText } from "@/views/Detail/helpers.js";

export default {
  name: "AuctionCard",
  props: ["item"],
  computed: {
    statusText() {
      return getStatusText(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.card {
  border: none;
  border-radius: 6px;
  box-shadow: 0px 1px 10px #2f2f2f59;

  .row {
    [class*="col-"] {
      border-radius: 1px;
    }
  }

  .image {
    width: 92px;
    display: flex;
    text-align: center;
    align-items: center;
  }

  .body {
    flex-grow: 1;
  }

  .card-img {
    max-width: 70px;
    overflow: hidden;
  }

  .card-body {
    padding: 0.5rem;
    border-left: 1px solid #ebedec;

    .card-title {
      text-align: left;
      font-weight: bold;
    }

    .card-text {
      > div {
        line-height: 1.25;
      }

      > div > span:first-child {
        width: 40px;
      }
    }
  }

  .card-footer {
    padding: 0px;
    font-size: 28px;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: $primary-color-disabled;

    > div {
      padding: 0px 12px;
      border-radius: 0 0 6px 6px;

      span:nth-child(2) {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}
</style>
