import { render, staticRenderFns } from "./AlertSheet.vue?vue&type=template&id=3ef56472&scoped=true"
import script from "./AlertSheet.vue?vue&type=script&lang=js"
export * from "./AlertSheet.vue?vue&type=script&lang=js"
import style0 from "./AlertSheet.vue?vue&type=style&index=0&id=3ef56472&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef56472",
  null
  
)

export default component.exports