<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Accordion state="expand">
          <div slot="header">
            <p class="title d-flex align-items-center mb-2">
              <span class="lnr lnr-history mr-2"></span>
              ประวัติการร่วมจับของรางวัล
            </p>
          </div>
          <div class="card">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>จำนวนสิทธิ์</th>
                  <th>วันที่และเวลา</th>
                  <th>คะแนน</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="history in histories"
                  :key="history.id"
                  :class="{ active: history.user_id == user }"
                >
                  <td>{{ history.chances }}</td>
                  <td>{{ history.luckydraw_time | dateTimeShort }}</td>
                  <td>{{ history.points }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["histories", "user"],
};
</script>

<style lang="scss" scoped>
p {
  font-weight: 400;
}

.card {
  box-shadow: none;
  border: 1px solid #e3e3e3;
}

tr {
  > td:nth-child(1) {
    text-align: center;
  }
  > td:nth-child(2) {
    text-align: left;
  }
  > td:nth-child(3) {
    text-align: right;
  }
}
</style>
