<template>
  <div>
    <HistoryCard
      v-for="item in items"
      :key="item.id"
      :item="item"
      @click.native="goToDetail(item.id)"
    />
  </div>
</template>

<script>
import HistoryCard from "@/components/card/history.vue";

export default {
  name: "List",

  components: {
    HistoryCard,
  },

  props: ["items"],
  
  methods: {
    goToDetail(id) {
      this.$router.push({ name: "HistoryDetail", params: { id: id } });
    },
  },
};
</script>
