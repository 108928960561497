<template>
  <div>
    <HeartIcon v-if="item.favorite == 0" @click.stop="setFavorite(1)" />
    <HeartAltIcon v-else-if="item.favorite == 1" @click.stop="setFavorite(0)" />
    <HeartAltIcon v-else-if="item.favorite == 2" @click.stop="setFavorite(2)" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { addFavorite, removeFavorite } from "@/api/http";
import PopupOverlay from "@/components/shared/Popup.vue";

export default {
  props: ["item"],

  methods: {
    ...mapActions({
      fetch: `favorite/fetch`,
    }),

    async setFavorite(favorite) {
      try {
        if (favorite === 2) {
          this.displayOverlayMessage();
          return;
        }

        favorite == 1
          ? await addFavorite(this.item.id)
          : await removeFavorite(this.item.id);

        this.item.favorite = favorite;
        this.displayOverlayMessage();
      } catch (error) {
        this.handleResponseError(error.response);
      }
    },

    handleResponseError(response) {
      switch (response.data) {
        case "Cannot remove favorite":
          this.displayOverlayMessage(
            `ไม่สามารถนำรายการที่<br>เข้าร่วมประมูลหรือซื้อสิทธิ์จับรางวัลแล้วออกได้`
          );
          break;

        default:
          this.showAlert(this, response.data);
          break;
      }
    },

    getMessage() {
      const message =
        this.item.favorite == 2
          ? `ไม่สามารถนำรายการที่<br>เข้าร่วมประมูลหรือซื้อสิทธิ์จับรางวัลแล้วออกได้`
          : this.item.favorite == 1
          ? `เพิ่มในรายการของฉันเรียบร้อยแล้ว!`
          : `ลบออกจากรายการของฉันสำเร็จ`;

      return message;
    },

    displayOverlayMessage(message) {
      this.$modal.show(
        PopupOverlay,
        { message: message || this.getMessage() },
        {
          width: "80%",
          height: "auto",
          classes: "result-message",
        },
        {
          closed: async (event) => {
            await this.fetch();
          },
        }
      );
    },
  },
};
</script>

<style></style>
