<template>
  <div class="wrapper">
    <StatusBar :item="item" />
    <Navbar :counter="item.no_visit" />
    <Header :item="item" />
    <Swiper :images="images" />
    <LotNo :item="item" />
    <div class="mt-4 mb-2 text-center">
      <button class="btn btn-link" @click="showTermsModal = true">
        เงื่อนไขและข้อตกลง
      </button>
      <p v-if="item.type == 'luckydraw'" class="luckydraw_chances">
        สิทธิ์ที่ใช้ไป: {{ item.luckydraw_chances }} สิทธิ์
      </p>
    </div>
    <DateRange :item="item" />
    <HistoryAuction
      v-if="item.type == 'auction'"
      :histories="item.bid_history"
      :user="userInfo.user_id"
    />
    <WinnersList
      v-else-if="item.type == 'luckydraw'"
      :winners="item.luckydraw_winner"
      :user="userInfo.user_id"
    />
    <Info :item="item" />
    <transition
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <Terms
        v-if="showTermsModal"
        @back="showTermsModal = false"
        :terms="item.term_condition"
      />
    </transition>
  </div>
</template>

<script>
import { getLotById } from "@/api/http";
import { mapActions, mapGetters } from "vuex";
import StatusBar from "@/views/Detail/history/StatusBar.vue";
import Header from "@/views/Detail/Header.vue";
import Swiper from "@/views/Detail/Swiper.vue";
import DateRange from "@/views/Detail/DateRange.vue";
import HistoryAuction from "@/views/Detail/history/HistoryAuction.vue";
import WinnersList from "@/views/Detail/history/WinnersList.vue";
import Info from "@/views/Detail/Info.vue";
import LotNo from "@/views/Detail/LotNo.vue";
import Terms from "@/views/Detail/Terms.vue";

export default {
  name: "HistoryDetail",

  components: {
    StatusBar,
    Header,
    Swiper,
    DateRange,
    HistoryAuction,
    WinnersList,
    Info,
    LotNo,
    Terms,
  },

  data() {
    return {
      item: {},
      showTermsModal: false,
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),

    images() {
      const { image1, image2, image3, image4 } = this.item;
      return [image1, image2, image3, image4].filter((i) => i != null);
    },
  },

  async created() {
    await this.fetchUserInfo();
    await this.fetchLot();
  },

  methods: {
    ...mapActions(["fetchUserInfo"]),

    async fetchLot() {
      try {
        const id = this.$route.params.id;
        const response = await getLotById(id);
        this.item = Object.assign({ id: id }, response.data);
      } catch (error) {
        console.error(error);
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin-top: 55px;
  background-color: #f9fbfc;
}

.luckydraw_chances {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0px;
}

.animated {
  animation-duration: 0.45s;
}
</style>
