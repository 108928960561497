<template>
  <StatusBar type="luckydraw" :status="item.status">
    <div>
      <LuckyDrawIcon class="mx-2" />
      <span v-if="item.status == 'posted'" class="time">
        {{ item.start_date | date_med }}
      </span>
      <span
        v-else-if="status.status == 'ongoing' && status.moreThanTwoDays"
        class="time"
      >
        {{ item.end_date | date_med }}
      </span>
      <span
        v-else-if="status.status == 'ongoing' && !status.moreThanTwoDays"
        class="time"
      >
        <countdown :time="getCountdownMilliseconds()" :transform="transform">
          <template slot-scope="props">
            {{ props.totalHours }}:{{ props.minutes }}:{{ props.seconds }}
          </template>
        </countdown>
      </span>
      <span class="time" v-else>สิ้นสุดแล้ว</span>
    </div>
    <div>
      <span v-if="item.status == 'posted'" class="text">
        {{ item.minimum_points | numberWithCommas }} คะแนน
      </span>
      <span v-else class="text">
        ใช้สิทธิ์ {{ item.luckydraw_user_count | numberWithCommas }} คน
      </span>
    </div>
  </StatusBar>
</template>

<script>
import moment from "moment";
import StatusBar from "@/views/Detail/StatusBar.vue";
import {
  transformCountdownProps,
  getLuckyDrawDetailStatus,
} from "@/views/Detail/helpers";

export default {
  name: "LuckyDrawStatusBar",

  components: { StatusBar },

  props: ["item"],
  computed: {
    status() {
      return getLuckyDrawDetailStatus(this.item);
    },
  },
  methods: {
    transform: transformCountdownProps,

    getCountdownMilliseconds() {
      return moment(this.item.end_date).diff(moment(), "milliseconds");
    },
  },
};
</script>

<style></style>
