<template>
  <div class="wrapper">
    <p class="w-100 align-self-center">ประเภท:</p>
    <select class="custom-select" v-model="selected" @change="optionSelected">
      <option value="">ทั้งหมด</option>
      <option value="luckydraw">จับฉลาก</option>
      <option value="auction">ประมูล</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "FilterAction",

  props: {
    defaultSelected: {
      default: "",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  created() {
    this.selected = this.defaultSelected ? this.defaultSelected : "";
  },
  methods: {
    optionSelected() {
      this.$emit("input", this.selected);
      this.$emit("change", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  padding: 16px 8px;
  align-self: center;
  vertical-align: middle;

  > p {
    margin: 0px;
    width: 70px;
    margin-right: 6px;
  }

  > select {
    width: auto;
    height: auto;
    max-height: 40px;
    padding: 0px 8px;
    padding-right: 26px;
    border-color: #ebedec;
  }
}
</style>
