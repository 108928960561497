import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

import * as api from "@/api/http";

import pinned from "./pinned";
import auction from "./auction";
import favorite from "./favorite";
import luckydraw from "./luckydraw";

export default new Vuex.Store({
  state: {
    accessToken: "",
    userInfo: null,
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    userInfo(state) {
      return state.userInfo;
    },
  },
  actions: {
    setAccessToken({ commit }, data) {
      commit("SET_TOKEN", data);
      localStorage.setItem("accessToken", data);
    },
    async fetchUserInfo({ commit }) {
      await api
        .getUserInfo()
        .then((response) => commit("SET_USER_INFO", response.data));
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
  },
  modules: {
    pinned: pinned,
    auction: auction,
    favorite: favorite,
    luckydraw: luckydraw,
  },
  plugins: [new VuexPersistence({ storage: window.sessionStorage }).plugin],
});
