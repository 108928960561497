import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Money from "v-money";
import VueGtag from "vue-gtag";
import VModal from "vue-js-modal";
import VueHolder from "vue-holderjs";
import SlimDialog from "v-slim-dialog";
import VueCountdown from "@chenfengyuan/vue-countdown";

import "./mixins";
import "./filters";

import { apolloProvider } from "./utils/graphql";

Vue.use(VueHolder);
Vue.use(SlimDialog);
Vue.use(Money, { precision: 0 });

import HeartIcon from "@/assets/svg/heart.svg";
import HeartAltIcon from "@/assets/svg/heart_alt.svg";
import AuctionIcon from "@/assets/svg/auction.svg";
import LuckyDrawIcon from "@/assets/svg/luckydraw.svg";
import Navbar from "@/components/shared/Navbar.vue";
import Footer from "@/components/shared/Footer.vue";
import Favorite from "@/components/shared/Favorite.vue";

import Overlay from "@/components/shared/Overlay.vue";
import Accordion from "@/components/shared/Accordion.vue";

Vue.component("Overlay", Overlay);
Vue.component("Accordion", Accordion);
Vue.component("HeartIcon", HeartIcon);
Vue.component("HeartAltIcon", HeartAltIcon);
Vue.component("AuctionIcon", AuctionIcon);
Vue.component("LuckyDrawIcon", LuckyDrawIcon);
Vue.component("Navbar", Navbar);
Vue.component("Footer", Footer);
Vue.component("Favorite", Favorite);
Vue.component(VueCountdown.name, VueCountdown);

Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    clickToClose: true,
  },
});

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider: apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
