import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import AuctionDetail from "@/views/Detail/auction/Index.vue";
import LuckyDrawDetail from "@/views/Detail/luckydraw/Index.vue";
import HistoryDetail from "@/views/Detail/history/Index.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/main",
    name: "Home",
    component: Home,
  },
  {
    path: "/auction-detail/:id",
    name: "AuctionDetail",
    component: AuctionDetail,
  },
  {
    path: "/luckydraw-detail/:id",
    name: "LuckyDrawDetail",
    component: LuckyDrawDetail,
  },
  {
    path: "/history-detail/:id",
    name: "HistoryDetail",
    component: HistoryDetail,
  },
  // {
  //   path: "/unauthorized",
  //   name: "Unauthorized",
  //   meta: {
  //     action: "Unauthorized",
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// const whiteListPage = ["Unauthorized"];

// router.beforeEach((to, from, next) => {
//   if (whiteListPage.indexOf(to.meta.action) > -1) return next();

//   const accessToken = store.getters["accessToken"];

//   if (to.meta.action !== "Unauthorized" && !accessToken)
//     next({ name: "Unauthorized" });

//   if (accessToken && to.meta.action == "Unauthorized") {
//     next({ name: "Home" });
//   } else if (accessToken && to.meta.action !== "Unauthorized") {
//     next();
//   } else if (!accessToken && to.meta.action == "Unauthorized") {
//     next();
//   } else {
//     next({ name: "Unauthorized" });
//   }
// });

export default router;
