<template>
  <div class="card">
    <div class="card-img-top text-center">
      <div class="favorite">
        <Favorite :item="item" />
      </div>
      <img :src="item.image" :alt="item.name" />
    </div>

    <div class="card-body d-flex flex-column">
      <h5 class="card-title mb-auto">{{ item.name }}</h5>
      <div class="card-text">
        <div
          v-if="status.status == 'posted'"
          class="d-flex justify-content-between text-muted lh-1"
        >
          <span>เริ่ม</span>
          <span>{{ item.start_date | dateTimeMedium }}</span>
        </div>
        <div
          v-else-if="status.status == 'ongoing' && status.moreThanTwoDays"
          class="text-muted"
        >
          <p class="mb-0">สิ้นสุดใน</p>
          <p>{{ item.end_date | dateTimeMedium }} น.</p>
        </div>
        <div
          v-else-if="status.status == 'ongoing' && !status.moreThanTwoDays"
          class="text-muted"
        >
          <p class="mb-0">สิ้นสุดใน</p>
          <p class="count-down">
            <countdown
              :time="getCountdownMilliseconds()"
              :transform="transform"
            >
              <template slot-scope="props">
                {{ props.totalHours }} ชม. {{ props.minutes }} นาที
              </template>
            </countdown>
          </p>
        </div>
      </div>
    </div>

    <div class="card-footer text-white" :class="status.bgClass">
      <div class="d-flex justify-content-between">
        <span class="align-self-center">
          <AuctionIcon v-if="item.type == 'auction'" />
          <LuckyDrawIcon v-else />
        </span>
        <span v-if="item.status == 'posted'">
          {{ item.minimum_points | numberWithCommas }} คะแนน
        </span>
        <span v-else>
          {{
            item.bid_points === 0
              ? item.minimum_points
              : item.bid_points | numberWithCommas
          }}
          คะแนน
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getAuctionDetailStatus,
  transformCountdownProps,
  getLuckyDrawDetailStatus,
} from "@/views/Detail/helpers";

export default {
  name: "PinnedCard",
  props: ["item"],
  computed: {
    status() {
      return this.item.type == "auction"
        ? getAuctionDetailStatus(this.item)
        : getLuckyDrawDetailStatus(this.item);
    },
  },
  methods: {
    transform: transformCountdownProps,

    getCountdownMilliseconds() {
      return moment(this.item.end_date).diff(moment(), "milliseconds");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 10px #2f2f2f59;

  .card-img-top {
    height: 180px;
    max-height: 180px;
    overflow: hidden;
    padding-bottom: 6px;
    border-radius: 10px 10px 0 0;

    img {
      width: 100%;
    }

    > .favorite {
      top: 4px;
      right: 12px;
      position: absolute;
    }
  }

  .card-body {
    padding: 0.5rem;
    min-height: 120px;
    border-left: 0px;
    border-top: 1px solid #ccc;

    .card-title {
      max-height: 60px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 0px;

      // PA-122
      // https://stackoverflow.com/a/13924997
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .card-text {
      p {
        line-height: 1;
        margin-bottom: 0px;

        &:first-child {
          font-size: 16px;
        }
      }

      .count-down {
        line-height: 0;
        color: #ff4647;
        > span {
          line-height: 1;
          font-size: 32px;
        }
      }

      .lh-1 {
        line-height: 1;
      }
    }
  }

  .card-footer {
    padding: 0px;
    font-size: 28px;
    border-top: none;
    border-radius: 0 0 8px 8px;

    > div {
      padding: 0px 12px;
      border-radius: 0 0 10px 10px;
    }
  }
}
</style>
