import { getAuctions } from "@/api/http";

export default {
  namespaced: true,

  state: {
    lots: [],
  },

  getters: {
    list(state) {
      return state.lots;
    },
  },

  actions: {
    async fetch({ commit }) {
      const params = {
        order_by: localStorage.getItem("auction_order_by") || "earliest_start",
      };
      const { data } = await getAuctions(params);
      commit("SET_LIST", data);
    },
  },

  mutations: {
    SET_LIST(state, payload) {
      state.lots = payload;
    },
  },
};
