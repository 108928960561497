<template>
  <div class="wrapper">
    <p class="align-self-center">จัดเรียง:</p>
    <select class="custom-select flex-grow-1" v-model="selected" @change="optionSelected">
      <option value="earliest_start">วันที่เริ่มใกล้สุด</option>
      <option value="latest_start">วันที่เริ่มไกลสุด</option>
      <option value="nearest_end">เวลาใกล้สิ้นสุด</option>
      <option value="status">สถานะ</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Sorting",

  props: {
    defaultSelected: {
      default: "earliest_start",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  created() {
    this.selected = this.defaultSelected ? this.defaultSelected : "";
  },
  methods: {
    optionSelected() {
      this.$emit("input", this.selected);
      this.$emit("change", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  padding: 16px 8px;
  align-self: center;
  vertical-align: middle;

  > p {
    margin: 0px;
    margin-right: 6px;
  }

  > select {
    width: auto;
    height: auto;
    max-height: 40px;
    padding: 0px 8px;
    padding-right: 30px;
    border-color: #ebedec;
  }
}
</style>
