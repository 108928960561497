exports.isLessThanMinimumPoint = function(vm) {
  return (
    vm.point > 0 && vm.point < vm.minimumBidPoint + vm.item.minimum_increment
  );
};

exports.isMoreThanMaximumPoint = function(vm) {
  return vm.point > vm.minimumBidPoint + vm.item.maximum_points;
};

exports.showErrorMessageMinimumPoint = function(vm) {
  const point = vm.minimumBidPoint + vm.item.minimum_increment;
  vm.errorMessage = `อย่าพลาด!!โอกาสเป็นเจ้าของรางวัลนี้ <br/>
  กรุณาใส่คะแนนที่ต้องการประมูลอย่างน้อย ${point} คะแนน`;
};

exports.showErrorMessageEmptyOrZeroPoint = function(vm) {
  const point = vm.minimumBidPoint + vm.item.minimum_increment;
  vm.errorMessage = `อย่าพลาด!!โอกาสเป็นเจ้าของรางวัลนี้ <br/>
  กรุณาใส่คะแนนที่ต้องการประมูลอย่างน้อย ${point} คะแนน`;
};

exports.isEmptyOrZeroPoint = function(vm) {
  return vm.point === 0 || !vm.point;
};
