<template>
  <StatusBar type="auction" :status="item.status">
    <div>
      <AuctionIcon class="mx-2" />
      <span v-if="status.status == 'posted'" class="time">
        เริ่ม {{ item.start_date | date_med }}
      </span>
      <span
        v-else-if="status.status == 'ongoing' && status.moreThanTwoDays"
        class="time"
      >
        {{ item.end_date | date_med }}
      </span>
      <span
        v-else-if="status.status == 'ongoing' && !status.moreThanTwoDays"
        class="time"
      >
        <countdown :time="getCountdownMilliseconds()" :transform="transform">
          <template slot-scope="props">
            {{ props.totalHours }}:{{ props.minutes }}:{{ props.seconds }}
          </template>
        </countdown>
      </span>
      <span v-else class="time">สิ้นสุดการประมูล</span>
    </div>
    <span v-if="item.status == 'posted'" class="text">
      {{ item.minimum_points | numberWithCommas }} คะแนน
    </span>
    <span v-else-if="item.status == 'ongoing'" class="text">
      คะแนนประมูลขณะนี้
      <br />
      {{
        item.bid_points === 0
          ? item.minimum_points
          : item.bid_points | numberWithCommas
      }}
    </span>
    <span v-else class="text">
      {{
        item.bid_points === 0
          ? item.minimum_points
          : item.bid_points | numberWithCommas
      }}
      คะแนน
    </span>
  </StatusBar>
</template>

<script>
import moment from "moment";
import StatusBar from "@/views/Detail/StatusBar.vue";
import {
  getAuctionDetailStatus,
  transformCountdownProps,
} from "@/views/Detail/helpers";

export default {
  name: "AuctionStatusBar",

  components: { StatusBar },

  props: ["item"],
  computed: {
    status() {
      return getAuctionDetailStatus(this.item);
    },
  },
  methods: {
    transform: transformCountdownProps,

    getCountdownMilliseconds() {
      return moment(this.item.end_date).diff(moment(), "milliseconds");
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  line-height: 0.75;
  font-size: 1.22em;
}
</style>
