import Vue from "vue";

Vue.mixin({
  methods: {
    showAlert: async (vm, message, options, classes) => {
      setTimeout(() => {
        if (classes) {
          document
            .querySelector(`.v-dialog-body .content`)
            .classList.add(classes);
        }
      }, 100);

      return vm.$dialogs.alert(message, options || { okLabel: "ตกลง" });
    },
  },
});
