<template>
  <div class="card mb-3 mx-2" @click="goToDetail(item.id)">
    <div class="row no-gutters">
      <div class="col-5 d-flex">
        <img
          class="card-img align-self-center mx-auto"
          :alt="item.name"
          :src="item.image"
          v-holder="{ img: '125x236', bg: 'dcdcdc' }"
        />
      </div>
      <div class="col-7">
        <div class="card-body d-flex align-items-start flex-column">
          <h5 class="card-title mb-auto ">{{ item.name }}</h5>
          <div class="card-text">
            <div class="mb-2" v-if="status.status == 'posted'">
              <div class="d-flex justify-content-between text-muted date-text">
                <span>เริ่ม</span>
                <span class="w-60">{{ item.start_date | date_med }}</span>
              </div>
              <div class="d-flex justify-content-between text-muted date-text">
                <span>สิ้นสุด</span>
                <span class="w-60">{{ item.end_date | date_med }}</span>
              </div>
            </div>
            <div
              v-else-if="status.status == 'ongoing' && status.moreThanTwoDays"
              class="text-muted mb-2"
            >
              <p class="mb-0">สิ้นสุดใน</p>
              <p>{{ item.end_date | dateTimeMedium }} น.</p>
            </div>
            <div
              v-else-if="status.status == 'ongoing' && !status.moreThanTwoDays"
              class="text-muted mb-2"
            >
              <p class="mb-0">สิ้นสุดใน</p>
              <p class="count-down">
                <countdown
                  :time="getCountdownMilliseconds()"
                  :transform="transform"
                >
                  <template slot-scope="props">
                    {{ props.totalHours }} ชม. {{ props.minutes }} นาที
                  </template>
                </countdown>
              </p>
            </div>
            <div class="mb-2" v-else-if="status.status == 'end'">
              <div class="d-flex justify-content-between text-muted date-text">
                <span>เริ่ม</span>
                <span class="w-60">{{ item.start_date | date_med }}</span>
              </div>
              <div class="d-flex justify-content-between text-muted date-text">
                <span>สิ้นสุด</span>
                <span class="w-60">{{ item.end_date | date_med }}</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center text-muted"
            >
              <Favorite :item="item" />
              <a href="javascript:void(0)" class="btn btn-link">
                <span>ดูรายละเอียด</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-white" :class="status.bgClass">
      <div class="d-flex justify-content-between">
        <span class="align-self-center">
          <AuctionIcon class="mx-2" />
          {{ status.statusText }}
        </span>
        <span v-if="item.status == 'posted'">
          {{ item.minimum_points | numberWithCommas }} คะแนน
        </span>
        <span v-else>
          {{
            item.bid_points === 0
              ? item.minimum_points
              : item.bid_points | numberWithCommas
          }}
          คะแนน</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getAuctionDetailStatus,
  transformCountdownProps,
} from "@/views/Detail/helpers";

export default {
  name: "AuctionCard",
  props: ["item"],
  computed: {
    status() {
      return getAuctionDetailStatus(this.item);
    },
  },
  methods: {
    transform: transformCountdownProps,

    getCountdownMilliseconds() {
      return moment(this.item.end_date).diff(moment(), "milliseconds");
    },

    goToDetail(id) {
      this.$router.push({ name: "AuctionDetail", params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .row {
    height: 185px;
    max-height: 185px;
    overflow-y: hidden;
  }

  .card-body {
    padding: 1rem;
    min-height: 185px;
    max-height: 185px;

    .card-title {
      max-height: 70px;
      text-align: left;
      font-weight: bold;

      // PA-122
      // https://stackoverflow.com/a/13924997
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .card-text {
      width: 100%;
      display: block;
      p {
        line-height: 1;
        margin-bottom: 0px;

        &:first-child {
          font-size: 16px;
        }
      }

      .count-down {
        line-height: 0;
        color: #ff4647;
        > span {
          line-height: 1;
          font-size: 32px;
        }
      }

      .btn-link {
        padding: 0px;
        line-height: 1;
        span {
          font-size: 18px;
        }
      }
    }
  }

  .card-footer {
    padding: 0px;
    border-top: none;
    border-radius: 0 0 8px 8px;

    > div {
      padding: 0px 12px;
      border-radius: 0 0 10px 10px;
    }
  }
}
</style>
