<template>
  <transition
    enter-active-class="animated slideInUp"
    leave-active-class="animated slideOutDown"
  >
    <AlertSheet @submit="$emit('close')">
      <div class="text-center">
        <p class="h2 my-4">เสียใจด้วย<br />สิ้นสุดการจับรางวัลแล้ว</p>
      </div>
    </AlertSheet>
  </transition>
</template>

<script>
import AlertSheet from "@/components/sheet/AlertSheet.vue";
export default {
  components: {
    AlertSheet,
  },
};
</script>

<style></style>
