<template>
  <div>
    <PinnedList />
    <TabList />
  </div>
</template>

<script>
import TabList from "@/views/Main/TabList.vue";
import PinnedList from "@/views/Main/PinnedList.vue";

export default {
  name: "Main",

  components: {
    TabList,
    PinnedList,
  },
};
</script>
