<template>
  <div v-if="enable_luckydraw">
    <div class="tabbar">
      <label class="tab" :class="{ active: type == 'luckydraw' }">
        <input type="radio" value="luckydraw" v-model="type" />
        <LuckyDrawIcon />
        จับฉลาก
      </label>
      <label class="tab" :class="{ active: type == 'auction' }">
        <input type="radio" value="auction" v-model="type" />
        <AuctionIcon />
        ประมูล
      </label>
    </div>
    <swiper
      class="swiper tab-content"
      :options="swiperOption"
      ref="swiperContent"
    >
      <LuckyDrawList class="swiper-slide" />
      <AuctionList class="swiper-slide" />
    </swiper>
  </div>

  <AuctionList v-else />
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import AuctionList from "@/views/Main/AuctionList.vue";
import LuckyDrawList from "@/views/Main/LuckyDrawList.vue";

export default {
  name: "TabList",

  components: {
    Swiper,
    SwiperSlide,

    AuctionList,
    LuckyDrawList,
  },

  data() {
    const type = this.getActiveSlide();

    return {
      type,
      swiperOption: {
        noSwiping: true,
        onlyExternal: true,
        allowTouchMove: false,
      },
      enable_luckydraw: +process.env.VUE_APP_ENABLE_LUCKYDRAW === 1,
    };
  },

  watch: {
    type(v) {
      const swiperContent = this.$refs.swiperContent.$swiper;
      swiperContent.slideTo(v == "luckydraw" ? 0 : 1);
      localStorage.setItem("main_page_active_slide", v == "luckydraw" ? 0 : 1);
    },
  },

  mounted() {
    if (!this.enable_luckydraw) {
      return;
    }
    
    const activeIndex = localStorage.getItem("main_page_active_slide") || 0;

    const swiperContent = this.$refs.swiperContent.$swiper;
    swiperContent.slideTo(parseInt(activeIndex), false, false);
  },

  methods: {
    getActiveSlide() {
      let type = "luckydraw";
      const saved = localStorage.getItem("main_page_active_slide");

      switch (saved) {
        case "0":
          type = "luckydraw";
          break;
        case "1":
          type = "auction";
          break;
      }
      return type;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.tabbar {
  margin: auto 10px;
  border-bottom: 2px solid #ccc;
  .tab {
    border: 0;
    opacity: 1;
    width: 50%;
    height: 100%;
    outline: none;
    cursor: pointer;
    font-size: 24px;
    background: none;
    color: #000000;
    border-top: none;
    padding-top: 10px;
    text-align: center;
    align-items: center;
    padding-bottom: 10px;
    display: inline-block;
    justify-content: center;
    border-bottom: 3px solid transparent;

    top: 2px;
    margin-bottom: 0px;
    position: relative;

    path {
      fill: black;
    }

    &.active {
      opacity: 1;
      color: $primary-color;
      border-color: $primary-color;
      transition: all 0.5s;

      path {
        fill: $primary-color;
      }
    }

    > input[type="radio"] {
      visibility: hidden;
    }
  }
}
</style>
