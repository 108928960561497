<template>
  <div class="filter">
    <p class="flex-shrink-1 align-self-center">จัดเรียง:</p>
    <select
      class="custom-select w-100"
      v-model="selected"
      @change="optionSelected"
    >
      <option value="earliest_start">วันที่เริ่มใกล้สุด</option>
      <option value="latest_start">วันที่เริ่มไกลสุด</option>
      <option value="nearest_end">เวลาใกล้สิ้นสุด</option>
      <option value="status">สถานะ</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Sorting",

  props: {
    defaultSelected: {
      default: "earliest_start",
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  created() {
    this.selected = this.defaultSelected ? this.defaultSelected : "";
  },
  methods: {
    optionSelected() {
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  padding: 16px;
  vertical-align: middle;

  > p {
    margin: 0px;
    width: 70px;
  }

  > select {
    height: auto;
    padding: 0px 12px;
    border-color: #ebedec;
  }
}
</style>
