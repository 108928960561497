import { getPinnedList } from "@/api/http";

export default {
  namespaced: true,

  state: {
    lots: [],
  },

  getters: {
    pinnedList(state) {
      return state.lots;
    },
  },

  actions: {
    async getPinnedList({ commit }) {
      const { data } = await getPinnedList("yes");
      commit("SET_PINNED_LIST", data);
    },
  },

  mutations: {
    SET_PINNED_LIST(state, payload) {
      state.lots = payload;
    },
  },
};
