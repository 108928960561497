<template>
  <div class="form container-fluid">
    <Overlay v-if="isLoading" />
    <p class="title">
      ใช้คะแนนร่วมจับของรางวัล<br />
      {{ points | numberWithCommas }} คะแนนต่อสิทธิ์
    </p>

    <div class="row form-action">
      <div class="col-12">
        <button
          class="btn btn-default disable-dbl-tap-zoom"
          @click="decrease"
          :disabled="disabledDecrease"
        >
          -
        </button>
        <money
          v-bind="money"
          class="form-control"
          v-model="chancePoint"
          :disabled="disabledInputChance"
        ></money>
        <button
          class="btn btn-default disable-dbl-tap-zoom"
          @click="increase"
          :disabled="disabledIncrease"
        >
          +
        </button>
      </div>
      <div class="col-12">
        <p v-if="errorMessage != ''" class="error">
          {{ errorMessage }}
        </p>
        <p v-else class="points">
          {{ totalUsePoint | numberWithCommas }} คะแนน
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p>
          การกดปุ่มเพื่อร่วมประมูลเท่ากับข้าพเจ้ายอมรับ<br />
          <button class="btn btn-link" @click="showTermsModal = true">
            เงื่อนไขและข้อตกลง
          </button>
        </p>
        <button
          type="button"
          :disabled="!canDraw"
          @click.stop="confirm"
          class="btn btn-primary btn-lg btn-block"
        >
          <LuckyDrawIcon class="mx-2" />
          กดเพื่อร่วมจับรางวัล
        </button>
        <br />
        <p class="h2">สิทธิ์ปัจจุบัน: {{ lot.luckydraw_chances }} สิทธิ์</p>
      </div>
    </div>
    <transition
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight"
    >
      <Terms
        v-if="showTermsModal"
        @back="showTermsModal = false"
        :terms="lot.term_condition"
      />
    </transition>
    <transition
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <ConfirmSheet
        v-if="showConfirmModal"
        @cancel="closeConfirmModal"
        @submit="submit"
      >
        <div class="text-center">
          <p class="h2 my-4">
            ยืนยัน ใช้
            {{ totalUsePoint | numberWithCommas }}
            คะแนน<br />เพื่อร่วมการจับของรางวัล
          </p>
          <p class="text-danger">
            <span class="h3">คุณจะไม่ได้รับคะแนนคืน</span>
            <br />
            เนื่องจากเป็นการใช้คะแนนร่วมจับของรางวัล
          </p>
        </div>
      </ConfirmSheet>
    </transition>
  </div>
</template>

<script>
import { draw, getLotById } from "@/api/http";
import { mapGetters } from "vuex";
import {
  isEmptyOrZeroPoint,
  isLessThanMinimumPoint,
  isMoreThanMaximumPoint,
  showErrorMessageMinimumPoint,
  showErrorMessageEmptyOrZeroPoint,
} from "@/views/Detail/luckydraw/validation";
import Terms from "@/views/Detail/Terms.vue";
import ConfirmSheet from "@/components/sheet/ConfirmSheet.vue";

export default {
  name: "LuckyDrawForm",

  components: {
    Terms,
    ConfirmSheet,
  },

  props: ["item"],

  data() {
    return {
      id: null,
      points: 0,
      lot: this.item,
      canDraw: true,
      chancePoint: 1,
      isLoading: true,
      errorMessage: "",
      showTermsModal: false,
      showConfirmModal: false,

      disabledIncrease: true,
      disabledDecrease: false,
      disabledInputChance: false,

      drawError: "",

      money: {
        min: 1,
        max: 1000,
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
    };
  },

  watch: {
    points(v) {
      this.errorMessage = "";
      setTimeout(() => {
        this.canDraw = this.isCanMakeDraw();
      }, 700);
    },
    item(v) {
      this.lot = v;
      this.points = this.lot.minimum_points;
      this.initUpDownButtonState();
      this.canDraw = this.isCanMakeDraw();
    },
    userInfo(v) {
      this.canDraw = this.isCanMakeDraw();
    },
    chancePoint(v) {
      this.errorMessage = ``;

      this.validateUpDownPoint();
    },
  },

  computed: {
    ...mapGetters(["userInfo"]),

    totalUsePoint() {
      return this.points * this.chancePoint;
    },
  },

  methods: {
    initUpDownButtonState() {
      if (this.lot.status != "ongoing") {
        this.disabledIncrease = true;
        this.disabledDecrease = true;
        this.disabledInputChance = true;
      } else {
        this.disabledIncrease = false;
        this.disabledDecrease = false;
        this.disabledInputChance = false;
      }
    },

    isCanMakeDraw() {
      if (this.lot.status != "ongoing") {
        return false;
      }

      if (this.userInfo.points < this.lot.minimum_points) {
        const need = this.numberWithCommas(this.lot.minimum_points);
        this.errorMessage = `ใช้ ${need} คะแนน / คะแนนของคุณไม่เพียงพอ`;
        return false;
      }

      if (this.userInfo.points < this.totalUsePoint) {
        const need = this.numberWithCommas(this.totalUsePoint);
        this.errorMessage = `ใช้ ${need} คะแนน / คะแนนของคุณไม่เพียงพอ`;
        return false;
      }

      if (
        this.lot.max_no_entries != null &&
        this.chancePoint > this.lot.max_no_entries - this.lot.luckydraw_chances
      ) {
        const need = this.numberWithCommas(this.lot.minimum_points);
        this.errorMessage = `ใช้ ${this.chancePoint} สิทธิ์ / เกินจำนวนสิทธิ์สูงสุดที่อนุญาต`;

        this.canDraw = false;
        this.disabledIncrease = true;
        return;
      }

      return true;
    },

    numberWithCommas(v) {
      return this.$options.filters.numberWithCommas(v);
    },

    validateUpDownPoint() {
      if (this.userInfo.points < this.lot.minimum_points) {
        const need = this.numberWithCommas(this.lot.minimum_points);
        this.errorMessage = `ใช้ ${need} คะแนน / คะแนนของคุณไม่เพียงพอ`;

        this.canDraw = false;
        this.disabledIncrease = true;
        this.disabledDecrease = false;
        return;
      }

      if (
        this.lot.max_no_entries != null &&
        this.chancePoint > this.lot.max_no_entries - this.lot.luckydraw_chances
      ) {
        const need = this.numberWithCommas(this.chancePoint);
        this.errorMessage = `ใช้ ${need} สิทธิ์ / เกินจำนวนสิทธิ์สูงสุดที่อนุญาต`;

        this.canDraw = false;
        this.disabledIncrease = true;
        this.disabledDecrease = false;
        return;
      }

      if (this.chancePoint <= 0) {
        this.errorMessage = `กรุณาระบุอย่างน้อย 1 สิทธิ์`;

        this.canDraw = false;
        this.disabledIncrease = false;
        this.disabledDecrease = true;
        return;
      }

      if (this.userInfo.points < this.lot.minimum_points + this.totalUsePoint) {
        const need = this.numberWithCommas(this.totalUsePoint);
        this.errorMessage = `ใช้ ${need} คะแนน / คะแนนของคุณไม่เพียงพอ`;

        this.canDraw = false;
        this.disabledIncrease = true;
        this.disabledDecrease = false;
        return;
      }

      if (
        this.lot.max_no_entries == null &&
        this.userInfo.points < this.points
      ) {
        const sum = this.points + this.lot.minimum_points;
        const need = this.numberWithCommas(sum);
        this.errorMessage = `ใช้ ${need} คะแนน / คะแนนของคุณไม่เพียงพอ`;

        this.canDraw = false;
        this.disabledIncrease = true;
        this.disabledDecrease = false;
        return;
      }

      this.canDraw = true;
      this.disabledIncrease = false;
      this.disabledDecrease = false;
    },

    decrease() {
      this.chancePoint == 1 ? this.chancePoint : this.chancePoint--;
    },

    increase() {
      if (this.lot.max_no_entries != null) {
        const max = this.lot.max_no_entries - this.lot.luckydraw_chances;
        this.chancePoint - max === 1 ? this.chancePoint : this.chancePoint++;
      } else {
        this.chancePoint++;
      }
    },

    validate() {
      this.errorMessage = ``;

      if (isLessThanMinimumPoint(this)) {
        showErrorMessageMinimumPoint(this);
        return false;
      }

      return true;
    },

    confirm() {
      if (!this.validate()) {
        return;
      }

      this.openConfirmModal();
    },

    async submit() {
      this.drawError = "";
      this.isLoading = true;

      try {
        const result = await draw(this.id, this.chancePoint);
        setTimeout(() => {
          this.isLoading = false;
          this.closeConfirmModal();
          setTimeout(() => {
            this.$emit("success");
          }, 500);
        }, 1000);
      } catch (error) {
        this.drawError = error.response.data;
        setTimeout(() => {
          this.isLoading = false;
          this.closeConfirmModal();
          this.emitError();
        }, 700);
      }
    },

    emitError(error) {
      setTimeout(() => {
        this.$emit("error", this.mappingBidErrorMessage());
      }, 500);
    },

    mappingBidErrorMessage() {
      let message = this.drawError;
      switch (true) {
        case message == "Cannot enter lucky draw now":
          message = `เสียใจด้วย สิ้นสุดการจับของรางวัลแล้ว`;
          break;
        case message == "Invalid luckydraw_chances":
          message = `Invalid luckydraw_chances`;
          break;
      }
      return message;
    },

    openConfirmModal() {
      this.showConfirmModal = true;
    },

    closeConfirmModal() {
      this.showConfirmModal = false;
    },
  },

  async created() {
    this.isLoading = false;
  },

  mounted() {
    this.id = this.$route.params.id;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.form {
  text-align: center;

  .title {
    font-size: 30px;
    font-weight: 500;
    margin-top: 30px;
    line-height: 1.25;
    margin-bottom: 20px;
  }

  .form-action {
    p,
    span,
    button {
      font-size: 30px;
      font-weight: 500;
    }

    input {
      width: 100px;
      height: 50px;
      line-height: 1;
      font-weight: 500;
      color: #007aff;
      margin: 0px 10px;
      text-align: center;
      display: inline-block;
      border-color: #efefef;
    }

    .error {
      font-size: 22px;
      color: #ff4647;
      font-weight: 400;
      margin-top: 16px;
    }

    button {
      width: 50px;
      height: 50px;
      line-height: 1;
      font-weight: 500;
      text-align: center;
      color: $primary-color;
      border-color: #efefef;
    }

    span {
      padding: 0px 30px;
    }
  }

  .btn-block {
    font-size: 30px;
    line-height: 1;
  }
}

.animated {
  animation-duration: 0.45s;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>
