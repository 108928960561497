<template>
  <div class="status-bar" :class="{ 'bg-primary': item.is_winner }">
    <AuctionIcon v-if="item.type == 'auction'" class="mx-2" />
    <LuckyDrawIcon v-else-if="item.type == 'luckydraw'" class="mx-2" />
    <span class="text">{{ statusText }}</span>
  </div>
</template>

<script>
import { getHistoryStatusText as getStatusText } from "@/views/Detail/helpers.js";

export default {
  props: ["item"],
  computed: {
    statusText() {
      return getStatusText(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.status-bar {
  top: 0px;
  z-index: 2;
  width: 100%;
  position: fixed;
  padding: 5px 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-color-disabled;

  > svg {
    margin-bottom: 0.3rem;
  }

  span {
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
