<template>
  <div>
    <Sorting
      v-model="defaultParams.order_by"
      :defaultSelected="defaultParams.order_by"
    />
    <LuckyDrawCard v-for="item in items" :key="item.id" :item="item" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";

import Sorting from "@/views/Main/Sorting.vue";
import LuckyDrawCard from "@/components/card/luckydraw.vue";

export default {
  name: "LuckyDrawList",

  components: {
    Sorting,
    LuckyDrawCard,
  },

  data() {
    return {
      items: [],
      defaultParams: {
        order_by: "earliest_start",
      },
    };
  },

  apollo: {
    $subscribe: {
      eventAdded: {
        query: gql`
          subscription {
            eventAdded {
              lot_id
              user_id
              event
              points
              event_id
              product_name
            }
          }
        `,
        result({ data }) {
          const { lot_id, user_id, event, points } = data.eventAdded;

          switch (event) {
            case "new_luckydraw_user":
              const itemIndex = this.items.findIndex((i) => i.id === lot_id);

              if (itemIndex > -1) {
                this.items[itemIndex].luckydraw_user_count += 1;
              }
              break;

            default:
              break;
          }
        },
      },
    },
  },

  watch: {
    list(v) {
      this.items = v;
    },
    "defaultParams.order_by"(v) {
      localStorage.setItem("luckydraw_order_by", v);
      this.fetchLot();
    },
  },

  computed: {
    ...mapGetters({
      list: `luckydraw/list`,
    }),
  },

  beforeMount() {
    this.defaultParams.order_by =
      localStorage.getItem("luckydraw_order_by") || "earliest_start";
  },

  methods: {
    ...mapActions({
      fetch: `luckydraw/fetch`,
    }),

    async fetchLot() {
      try {
        await this.fetch();
      } catch (error) {
        console.error(error);
      }
    },
  },

  async created() {
    await this.fetchLot();
  },
};
</script>
