<template>
  <div id="home" class="tabbar-android">
    <Overlay v-if="isLoading" />
    <div class="header">
      {{ enable_luckydraw ? "จับฉลาก / ประมูล" : "ประมูล" }}
    </div>
    <div id="tabbar" class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <p class="my-0">ทั้งหมด</p>
        </div>
        <div class="swiper-slide">
          <p class="my-0">รายการของฉัน</p>
        </div>
        <div class="swiper-slide">
          <p class="my-0">ประวัติ</p>
        </div>
      </div>
    </div>
    <div id="page-content" class="swiper-container">
      <div class="swiper-wrapper">
        <Main class="swiper-slide" data-hash="all" />
        <MyList class="swiper-slide" data-hash="my-list" />
        <History class="swiper-slide" data-hash="history" />
      </div>
    </div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated slideOutDown"
    >
      <ConsentSheet
        v-if="showConsentModal"
        @accept="closeConsentModal"
        @read="closeConsentModal"
      />
    </transition>
  </div>
</template>

<script>
import Swiper from "swiper";
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";

import Main from "@/views/Main/Index.vue";
import MyList from "@/views/MyList/Index.vue";
import History from "@/views/History/Index.vue";
import ConsentSheet from "@/components/sheet/ConsentSheet.vue";

export default {
  name: "Home",

  components: {
    Main,
    MyList,
    History,
    ConsentSheet,
  },

  apollo: {
    $subscribe: {
      eventAdded: {
        query: gql`
          subscription {
            eventAdded {
              event
            }
          }
        `,
        result({ data }) {
          const { event } = data.eventAdded;

          switch (event) {
            case "lot_posted":
              this.slideChange();
              break;

            default:
              break;
          }
        },
      },
    },
  },

  data() {
    return {
      tabbar: null,
      activeTab: 0,
      isLoading: true,
      pageContent: null,
      showConsentModal: false,
      enable_luckydraw: +process.env.VUE_APP_ENABLE_LUCKYDRAW === 1,
    };
  },

  computed: {
    ...mapGetters("pinned", ["pinnedList"]),
  },

  methods: {
    ...mapActions({
      fetchAuction: `auction/fetch`,
      fetchLuckydraw: `luckydraw/fetch`,
      getMyList: `favorite/fetch`,
      getPinnedList: `pinned/getPinnedList`,
    }),

    async initLayout(tabSelectors) {
      const { tabbarSelector, tabContentSelector } = tabSelectors;

      const tabLayout = await new Promise(function(resolve, reject) {
        const tabbar = new Swiper(tabbarSelector, {
          slidesPerView: "auto",
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
        });

        const pageContent = new Swiper(tabContentSelector, {
          hashNavigation: {
            watchState: true,
          },
          thumbs: {
            swiper: tabbar,
          },
        });

        const tabLayout = { tabbar: tabbar, pageContent: pageContent };

        resolve(tabLayout);
      });

      this.tabbar = tabLayout.tabbar;
      this.pageContent = tabLayout.pageContent;
    },

    openConsentModal() {
      this.showConsentModal = true;
    },

    closeConsentModal() {
      this.showConsentModal = false;
    },

    async slideChange() {
      this.activeTab = this.pageContent.activeIndex;

      switch (this.activeTab) {
        case 0:
          await this.getPinnedList();
          await this.fetchAuction();
          await this.fetchLuckydraw();
          break;
        case 1:
          await this.getMyList();
          break;

        default:
          break;
      }
    },
  },

  async mounted() {
    await this.initLayout({
      tabbarSelector: "#tabbar",
      tabContentSelector: "#page-content",
    });

    this.pageContent.on("slideChange", this.slideChange);

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/home";
</style>
