<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";
import { getAllEvents, setReadEvent, getAccesToken } from "@/api/http";
import subscriptionsHandle from "@/subscriptions";

export default {
  name: "App",

  data() {
    return {
      userId: -1,
    };
  },

  async created() {
    let QUERY = "";

    switch (process.env.NODE_ENV) {
      case "development":
        QUERY = process.env.VUE_APP_SALEFORCE_QUERY;
        break;

      default:
        QUERY = this.$route.query.QUERY || this.$route.query.query;
        break;
    }

    const { token, user } = await getAccesToken(QUERY);

    this.userId = user;

    this.setAccessToken(token);
    this.refreshSubscriptions();
    await this.fetchAllEvent();
    this.$router.push({ name: "Home" });
  },

  computed: {
    ...mapGetters({
      auctionList: `auction/list`,
    }),
  },

  apollo: {
    $subscribe: {
      eventAdded: {
        query: gql`
          subscription {
            eventAdded {
              lot_id
              user_id
              event
              points
              event_id
              product_name
            }
          }
        `,
        result({ data }) {
          subscriptionsHandle(this, data);
        },
      },
    },
  },

  methods: {
    ...mapActions(["setAccessToken"]),

    refreshSubscriptions() {
      const vm = this;
      setTimeout(() => {
        vm.$apollo.subscriptions.eventAdded.refresh();
      }, 2000);
    },

    async fetchAllEvent() {
      const response = await getAllEvents();
      const data = response.data;

      if (data.events && data.events.length > 0) {
        const messages = data.events.reduce((message, eventObj) => {
          const { lot_id, user_id, event, product_name } = eventObj;

          switch (event) {
            case "fail_bid":
              if (this.userId === user_id) {
                return (message += `ขออภัย ระบบมีปัญหาขัดข้อง ไม่สามารถทำรายการ ${lot_id} - ${product_name} ได้ กรุณาทำรายการใหม่อีกครั้ง<hr>`);
              }
              break;

            case "fail_luckydraw":
              if (this.userId === user_id) {
                return (message += `ขออภัย ระบบมีปัญหาขัดข้อง ไม่สามารถทำรายการ ${lot_id} - ${product_name} ได้ กรุณาทำรายการใหม่อีกครั้ง<hr>`);
              }
              break;

            case "refund_bid":
              if (this.userId === user_id) {
                return (message += `คุณได้รับการคืนคะแนนจากรายการ ${lot_id} - ${product_name} เนื่องจากมีผู้ร่วมประมูลให้คะแนนมากกว่าคุณ<hr>`);
              }
              break;

            case "refund_bid_abort":
              if (this.userId === user_id) {
                return (message += `คุณได้รับการคืนคะแนนจากรายการ ${lot_id} - ${product_name} เนื่องจากกิจกรรมถูกยกเลิก<hr>`);
              }
              break;

            case "refund_luckydraw":
              if (this.userId === user_id) {
                return (message += `คุณได้รับการคืนคะแนนจากรายการ ${lot_id} - ${product_name} เนื่องจากกิจกรรมถูกยกเลิก<hr>`);
              }
              break;

            default:
              break;
          }
        }, ``);

        setTimeout(() => {
          document.querySelector(
            `.v-dialog-body .content`
          ).innerHTML = `${messages}`;
        }, 100);

        this.showAlert(this, ``);
        await setReadEvent(data.last_event_id);
      }
    },
  },
};
</script>
<style lang="scss">
@import "node_modules/v-slim-dialog/dist/v-slim-dialog";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/swiper/swiper.scss";
@import "@/styles/app.scss";
</style>
