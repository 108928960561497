import qs from "qs";
import axios from "axios";

function getQueryString() {
  var qd = {};

  if (location.search)
    location.search
      .substr(1)
      .split("&")
      .forEach(function(item) {
        var s = item.split("="),
          k = s[0],
          v = s[1] && decodeURIComponent(s[1]); //  null-coalescing / short-circuit
        (qd[k] = qd[k] || []).push(v); // null-coalescing / short-circuit
      });

  return qd;
}

export async function getAccesToken(QUERY) {
  var qd = getQueryString();

  const form = {};

  if ("QUERY" in qd) {
    form["QUERY"] = QUERY;
  }

  if ("query" in qd) {
    form["query"] = QUERY;
  }

  const response = await axios.post(
    `${process.env.VUE_APP_BASE_API_URL}/sfdc/auth/login`,
    form,
    {
      headers: {
        "x-application-secret-key": process.env.VUE_APP_SECRET_KEY,
      },
    }
  );

  return response.data;
}

// PMI Mini VIVI Auction api
const amsapi = axios.create({
  timeout: 120000,
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

amsapi.interceptors.request.use(function(config) {
  // Do something before request is sent
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});

// Add a response interceptor
amsapi.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 401) {
      window.location = "/";
    }
    return Promise.reject(error);
  }
);

export async function getAuctions(params) {
  const queryString = qs.stringify(params, {
    addQueryPrefix: false,
  });

  return await amsapi.get(`/sfdc/api/lot?type=auction&${queryString}`);
}

export async function getLuckyDraws(params) {
  const queryString = qs.stringify(params, {
    addQueryPrefix: false,
  });

  return await amsapi.get(`/sfdc/api/lot?type=luckydraw&${queryString}`);
}

export async function getMyList(params) {
  const queryString = qs.stringify(params, {
    addQueryPrefix: true,
  });

  return await amsapi.get(`/sfdc/api/lot/my${queryString}`);
}

export async function getPinnedList() {
  const enable_luckydraw = +process.env.VUE_APP_ENABLE_LUCKYDRAW === 1;
  return await amsapi.get(
    `/sfdc/api/lot?pinned=yes${!enable_luckydraw ? "&type=auction" : ""}`
  );
}

export async function getHistoryList(params) {
  const queryString = qs.stringify(params, {
    addQueryPrefix: true,
  });

  return await amsapi.get(`/sfdc/api/lot/history${queryString}`);
}

export async function getLotById(id) {
  return await amsapi.get(`/sfdc/api/lot/${id}`);
}

export async function getUserInfo() {
  return await amsapi.get(`/sfdc/api/user/info`);
}

export async function bid(id, point) {
  return await amsapi.post(`/sfdc/api/lot/${id}/bid`, { bid_points: point });
}

export async function addFavorite(id) {
  return await amsapi.post(`/sfdc/api/lot/${id}/add_favorite`);
}

export async function removeFavorite(id) {
  return await amsapi.post(`/sfdc/api/lot/${id}/remove_favorite`);
}

export async function draw(id, point) {
  return await amsapi.post(`/sfdc/api/lot/${id}/luckydraw`, {
    luckydraw_chances: point,
  });
}

export async function getAllEvents() {
  return await amsapi.get(`/sfdc/api/event`);
}

export async function setReadEvent(id) {
  return await amsapi.post(`/sfdc/api/event/read/${id}`);
}
