<template>
  <div>
    <FilterAction
      @change="fetchLot"
      v-model="filterBy"
      v-if="enable_luckydraw"
      :defaultSelected="filterBy"
    />
    <List :items="items" :class="{ 'my-3': !enable_luckydraw }" />
  </div>
</template>

<script>
import { getHistoryList } from "@/api/http";
import FilterAction from "@/views/History/Filter.vue";
import List from "@/views/History/List.vue";

export default {
  name: "History",

  components: {
    FilterAction,
    List,
  },

  data() {
    return {
      items: [],
      filterBy: "",

      enable_luckydraw: +process.env.VUE_APP_ENABLE_LUCKYDRAW === 1,
    };
  },

  async created() {
    await this.fetchLot();
  },

  methods: {
    async fetchLot() {
      try {
        const params = this.getQueryParams();
        const response = await getHistoryList(params);
        this.items = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    getQueryParams() {
      let params = {};
      if (this.filterBy) {
        params.type = this.filterBy;
      }
      return params;
    },
  },
};
</script>

<style></style>
