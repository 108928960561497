<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="item in items" :key="item.id">
      <Pinned :item="item" @click.native="goToDetail(item.type, item.id)" />
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Pinned from "@/components/card/pinned.vue";

export default {
  name: "PinnedList",

  components: {
    Pinned,

    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      items: [],

      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },

  watch: {
    pinnedList(v) {
      this.items = v.slice(0, 4);
    },
  },

  computed: {
    ...mapGetters("pinned", ["pinnedList"]),
  },

  methods: {
    ...mapActions({
      getPinnedList: `pinned/getPinnedList`,
    }),

    async fetchLot() {
      try {
        await this.getPinnedList();
      } catch (error) {
        console.error(error);
      }
    },

    goToDetail(type, id) {
      this.$router.push({
        name: type == "auction" ? "AuctionDetail" : "LuckyDrawDetail",
        params: { id: id },
      });
    },
  },

  async created() {
    await this.fetchLot();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_color.scss";

.swiper {
  width: 100%;
  height: 410px;
  margin-top: 10px;

  padding: 10px;
  background-color: $primary-color;
}
</style>

<style lang="scss">
@import "@/styles/vendors/swiper.scss";
</style>
