<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <Accordion state="expand">
          <div slot="header">
            <p class="title d-flex align-items-center mb-2">
              <LuckyDrawIcon class="icon mx-2" />
              รายชื่อผู้ที่ได้รับของรางวัล
            </p>
          </div>
          <div class="card">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>ชื่อ</th>
                  <th>นามสกุล</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(winner, index) in winners" :key="index">
                  <td>{{ winner.firstname }}</td>
                  <td>{{ winner.lastname }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["winners", "user"],
};
</script>

<style lang="scss" scoped>
p {
  font-weight: 400;
}

.title {
  svg {
    margin-bottom: 0.5rem;
    path {
      fill: black;
    }
  }
}

.card {
  box-shadow: none;
  border: 1px solid #e3e3e3;
}

tr {
  > td:nth-child(1) {
    text-align: left;
  }
  > td:nth-child(2) {
    text-align: left;
  }
}
</style>
