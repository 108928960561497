import store from "@/store";
import { getLotById, setReadEvent } from "@/api/http";

export default async function(vm, data) {
  const { lot_id, user_id, event, event_id, product_name } = data.eventAdded;

  switch (event) {
    case "success_bid":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `คุณได้เข้าร่วมการประมูลแล้ว อย่าลืมเข้ามาติดตามลำดับการประมูลของคุณนะคะ`,
          null,
          `primary`
        );
      }
      break;

    case "success_luckydraw":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `ขอแสดงความยินดีด้วย คุณทำการร่วมจับรางวัลสำเร็จ กรุณารีเฟรชที่หน้าจอเพื่อแสดงคะแนนล่าสุดที่คุณมี`
        );
      }
      break;

    case "fail_bid":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `ขออภัย ระบบมีปัญหาขัดข้อง ไม่สามารถทำรายการ ${lot_id} - ${product_name ||
            ""} ได้ กรุณาทำรายการใหม่อีกครั้ง`
        );
      }
      break;

    case "fail_luckydraw":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `ขออภัย ระบบมีปัญหาขัดข้อง ไม่สามารถทำรายการ ${lot_id} - ${product_name ||
            ""} ได้ กรุณาทำรายการใหม่อีกครั้ง`
        );
      }
      break;

    case "refund_bid":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `ขณะนี้มีผู้ประมูลด้วยคะแนนมากกว่าคุณ เราได้คืนคะแนนให้คุณแล้ว นำคะแนนไปสู้ต่อนะคะ`,
          null,
          `primary`
        );
      }
      break;

    case "refund_bid_abort":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `คุณได้รับการคืนคะแนน เนื่องจากกิจกรรม ${lot_id} - ${product_name ||
            ""} ถูกยกเลิก กรุณากดรีเฟรชที่หน้าจอเพื่อดูคะแนน`
        );
      }
      break;

    case "refund_luckydraw":
      if (vm.userId === user_id) {
        vm.showAlert(
          vm,
          `คุณได้รับการคืนคะแนน เนื่องจากกิจกรรม ${lot_id} - ${product_name ||
            ""} ถูกยกเลิก กรุณากดรีเฟรชที่หน้าจอเพื่อดูคะแนน`
        );
      }
      break;

    case "max_bid":
      const response = await getLotById(lot_id);
      const item = Object.assign({}, { id: lot_id }, response.data);

      if (vm.userId === user_id) {
        vm.showAlert(vm, `ยินดีด้วย คุณเป็นผู้ชนะการประมูลรายการนี้`);
      } else {
        if (
          item.status === "end" &&
          item.maximum_points !== null &&
          item.bid_points === item.maximum_points
        ) {
          vm.showAlert(
            vm,
            `รายการประมูลสิ้นสุดลงแล้ว เนื่องจากมีผู้ใช้คะแนนในการแลกสินค้าเข้ามาค่ะ`
          );
        } else if (item.status === "end") {
          vm.showAlert(vm, `เสียใจด้วย การประมูลสิ้นสุดลงแล้ว`);
        }
      }
      break;

    case "lot_ongoing":
      updateLotStatus(lot_id, "ongoing");
      break;

    case "lot_end":
      updateLotStatus(lot_id, "end");
      break;

    case "lot_aborted":
      removeLot(lot_id);
      break;

    default:
      break;
  }

  if (event_id && vm.userId === user_id) {
    setReadEvent(event_id);
  }
}

function updateLotStatus(id, status) {
  const states = ["pinned", "auction", "luckydraw", "favorite"];

  states.map((s) =>
    store.state[s].lots.map((lot) => {
      if (lot.id == id) {
        lot.status = status;
      }
    })
  );
}

function removeLot(id) {
  const states = ["pinned", "auction", "luckydraw", "favorite"];

  states.map(
    (s) =>
      (store.state[s].lots = store.state[s].lots.filter((lot) => {
        return lot.id !== id;
      }))
  );
}
